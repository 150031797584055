
import { Component, Prop, Vue, Watch, Model, Ref } from 'vue-property-decorator'

import CustomerAutocomplete from '../CustomerAutocomplete.vue'
import { Dictionary } from 'vue-router/types/router'
import { Customer, Reservation } from '@/types'

export const initForm = () => ({
  customer: null,
  familyName: '',
  givenName: '',
  familyNameKana: '',
  givenNameKana: '',
  phoneNumber: '',
})

export type Form = ReturnType<typeof initForm>

@Component({
  components: {
    CustomerAutocomplete,
  },
})
export default class ReservationReserverForm extends Vue {
  @Prop({ type: Object, default: null }) readonly reserve!: null | Reservation
  @Model('change', { type: Object, default: null }) readonly value!: Form | null
  @Prop({ type: Object, default: () => ({}) }) readonly errors!: Dictionary<
    string[]
  >
  @Prop({ type: Boolean, default: true }) readonly requiredPhoneNumber!: boolean
  @Ref() readonly customerAutocomplete!: CustomerAutocomplete

  oldReserve = null as null | Reservation
  form = initForm()
  customer: null | Customer = null
  note = ''
  operationNote = ''
  smsSending = false
  @Watch('reserve', { immediate: true })
  onReserveChanged(reserve: null | Reservation) {
    // NOTE: reserveがnullの場合は新規作成の可能性があるので初期化しない
    if (reserve) {
      const old = this.oldReserve
      if (!old || old.id !== reserve.id || old.updatedAt != reserve.updatedAt) {
        this.form = initForm()
        let form = this.form
        Object.keys(form).forEach((k) => {
          // @ts-ignore
          form[k] = reserve[k]
        })
        this.onChange()
      }
    }
    this.oldReserve = reserve
  }

  @Watch('value', { deep: true })
  onValueChange(value: Form | null) {
    if (value && value !== this.form) Object.assign(this.form, value)
  }

  setCusomerSearch() {
    this.customerAutocomplete.focus()
    const { familyNameKana, givenNameKana, phoneNumber } = this.form
    this.customerAutocomplete.search = `${familyNameKana} ${givenNameKana} ${phoneNumber}`
  }

  onChange() {
    if (!this.form.phoneNumber) {
      // NOTE: nullの場合があるので空文字にしておく
      this.form.phoneNumber = ''
    }
    this.$emit('change', this.form)
  }

  onCustomer(customer: Customer | null) {
    // console.log('?onCustomer', customer)
    const attrs = [
      'familyName',
      'givenName',
      'familyNameKana',
      'givenNameKana',
      'phoneNumber',
    ] as const
    this.customer = customer
    if (!customer) {
      this.note = ''
      this.operationNote = ''
      return
    } else {
      this.note = customer.note
      this.operationNote = customer.operationNote
    }
    type Keys = (typeof attrs)[number]
    // if (attrs.some(k => this.form[k] && this.form[k] !== customer[k])) {
    //   if (!confirm('姓名・電話番号を上書きしますか？')) return
    // }
    attrs.forEach((key: Keys) => {
      this.form[key] = customer[key] || ''
    })
    this.onChange()
  }

  async saveNote() {
    try {
      await this.$api
        .customers(this.customer?.uuid)
        .partialUpdate({ note: this.note })
      this.$toast.success('備考を保存しました。')
    } catch (err) {
      console.error(err)
      this.$toast.error('備考の保存に失敗しました。')
    }
  }

  async saveOpeNote() {
    try {
      await this.$api
        .customers(this.customer?.uuid)
        .partialUpdate({ operationNote: this.operationNote })
      this.$toast.success('施術の注意点を保存しました。')
    } catch (err) {
      console.error(err)
      this.$toast.error('施術の注意点の保存に失敗しました。')
    }
  }

  async sendSmsApplink() {
    try {
      this.smsSending = true
      await this.$api.reservations(this.reserve?.id).sendSmsAppLink({
        phoneNumber: this.form.phoneNumber,
      })
      this.$toast.success('SMSでアプリの案内を送信しました。')
    } catch (err: any) {
      console.error(err)
      if (err.status === 400)
        this.$toast.error('失敗しました。電話番号が不正です。')
      else this.$toast.error('サーバーエラーです。しばらくあとに')
    }
    this.smsSending = false
  }
}
